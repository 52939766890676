import './css/Skinchanger.scss'
import {useAuth} from "../../contexts/AuthContext";
import Category from "../../components/Skinchanger/Category";
import MainBlock from "../../components/Skinchanger/MainBlock";
import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";


function Skinchanger() {

    const { user} = useAuth();

    const [activeWeapon, setActiveWeapon] = useState('')
    const [selectedSkins, setSelectedSkins] = useState([])

    useEffect(() => {
        document.title = 'CsTrigon | Skinchanger';
    }, [])

    useEffect(() => {

        if(user) {

            const getSelectedSkins = async () => {

                const response = await axiosApi.get(`/skinchanger/get`)
                setSelectedSkins(response.data.items)

            }

            getSelectedSkins()

        }

    }, [user]);

    return (

        <div className="skinchanger-page">
            <div className="container">

                <div className="top">
                    <div className="title">Инвентарь игрока</div>
                    <div className="nickname">{user.nickname}</div>
                </div>

            </div>

            <div className="container-fluid">

                <div className="body">

                    <Category
                        activeWeapon={activeWeapon}
                        setActiveWeapon={setActiveWeapon}
                        setSelectedSkins={setSelectedSkins}
                    />

                    <MainBlock
                        activeWeapon={activeWeapon}
                        selectedSkins={selectedSkins}
                        setSelectedSkins={setSelectedSkins}
                    />

                </div>

            </div>
        </div>

    )

}

export default Skinchanger