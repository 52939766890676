import './css/Header.scss'

import logo from '../../assets/images/logo.png';
import svg from '../../assets/images/trigon.svg';

import Navbar from './Navbar';

import {useEffect, useState} from "react";
import OutsideClickHandler from 'react-outside-click-handler';

import { useAuth } from '../../contexts/AuthContext';
import {Link} from "react-router-dom";
import {BsCashCoin, BsSteam} from "react-icons/bs";
import {FaCartShopping} from "react-icons/fa6";
import Shop from "../../pages/Shop/Shop";
import {FaDonate, FaRubleSign} from "react-icons/fa";
import Modal from "../UI/Modal";
import {axiosApi} from "../../Api";
import {CiLock} from "react-icons/ci";

const Header = () => {

    const { user, loginLink, logout} = useAuth();

    const alias = user.alias || user.id

    const [notificationShow, setNotificationShow] = useState(false)

    const [paymentMethods, setPaymentMethods] = useState([])
    const [activePaymentMethod, setActivePaymentMethod] = useState()
    const [amountPay, setAmountPay] = useState()
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const showNotification = (target) => {

        console.log(notificationShow)
        setNotificationShow(!notificationShow)

    }

    const handleOutsideClick = (event) => {
        const targetElement = event.target;

        if (targetElement && targetElement.classList.contains('exclude-me')) {
            setNotificationShow(!notificationShow)
            return;
        }
        setNotificationShow(false)

    };

    useEffect(() => {

        axiosApi.get('/payments/list').then(response => {

            setPaymentMethods(response.data)

            if(response.data.length) {

                setActivePaymentMethod(0)

            }

        }).catch(error => {
            console.log('[/payments/list]', error)
        })

    }, []);

    const donate = () => {

        if((paymentMethods[activePaymentMethod].link || amountPay > 0) && paymentMethods[activePaymentMethod]) {

            if(paymentMethods[activePaymentMethod].link) {

                window.location.href = paymentMethods[activePaymentMethod].link

            } else {

                axiosApi.post('/payments/get_url', {amount: amountPay, method_id: paymentMethods[activePaymentMethod].id}).then(response => {

                    if(response.data.url) window.location.href = response.data.url

                }).catch(error => {

                    console.log('[/payments/get_url]', error)

                })

            }



        }

    }

    return (
        <header>
            <div className="hTop">
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt=""/>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hBottom">
                <div className="container">
                    <Navbar/>
                    <div className="account">
                        {user ? (
                            <>
                                <Link to="/shop" className="btn btn-purple btn-shop">
                                    <FaCartShopping />
                                </Link>
                                <div className="btn btn-green btn-donate" onClick={() => setShowPaymentModal(!showPaymentModal)}>
                                    <FaDonate />
                                </div>
                                <div className="block">
                                    <div className="image">
                                        <Link to={`/profile/${alias}`}>
                                            <img src={user.avatar} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="info">
                                        <Link to={`/profile/${alias}`} className="nickname">
                                            {user.nickname}
                                        </Link>
                                        <div className="balance">{user.balance} <span className="balance-currency">Руб</span> </div>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <div className={`d-none btn btn-green btn-notification notread exclude-me ${notificationShow ? 'active' : ''}`}>
                                        <svg>
                                            <use xlinkHref={`${svg}#notification-icon`}></use>
                                        </svg>
                                    </div>
                                    <div className="btn btn-red btn-logout" onClick={logout}>
                                        <svg>
                                            <use xlinkHref={`${svg}#logout-icon`}></use>
                                        </svg>
                                    </div>
                                </div>
                                {/*<OutsideClickHandler onOutsideClick={handleOutsideClick}>*/}
                                {/*    <div className={`notification ${notificationShow ? 'show' : ''}`}>*/}
                                {/*        <div className="list">*/}
                                {/*            <div className="item counter">*/}
                                {/*                <div className="icon green no-stroke">*/}
                                {/*                    <svg>*/}
                                {/*                        <use xlinkHref={`${svg}#notification-icon`}></use>*/}
                                {/*                    </svg>*/}
                                {/*                </div>*/}
                                {/*                <div className="text">У вас 8 уведомлений</div>*/}
                                {/*            </div>*/}
                                {/*            <div className="item">*/}
                                {/*                <div className="btn-close"></div>*/}
                                {/*                <div className="top">*/}
                                {/*                    <div className="icon yellow no-fill">*/}
                                {/*                        <svg>*/}
                                {/*                            <use xlinkHref={`${svg}#fire-icon`}></use>*/}
                                {/*                        </svg>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="date">14 мая 2022 г, 14:17</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="bottom">*/}
                                {/*                    <div className="info">Здравствуйте, Макс Христовский, поздравляем Вас с успешной регистрацией!</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div className="item">*/}
                                {/*                <div className="btn-close"></div>*/}
                                {/*                <div className="top">*/}
                                {/*                    <div className="icon green no-fill">*/}
                                {/*                        <svg>*/}
                                {/*                            <use xlinkHref={`${svg}#nn2`}></use>*/}
                                {/*                        </svg>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="date">14 мая 2022 г, 14:17</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="bottom">*/}
                                {/*                    <div className="info">Макс Христовкий добавляет вас в друзья</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="buttons">*/}
                                {/*                    <div className="btn btn-green">Принять</div>*/}
                                {/*                    <div className="btn btn-red">Отклонить</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div className="item">*/}
                                {/*                <div className="btn-close"></div>*/}
                                {/*                <div className="top">*/}
                                {/*                    <div className="icon green no-fill">*/}
                                {/*                        <svg>*/}
                                {/*                            <use xlinkHref={`${svg}#nn2`}></use>*/}
                                {/*                        </svg>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="date">14 мая 2022 г, 14:17</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="bottom">*/}
                                {/*                    <div className="info">Макс Христовкий добавляет вас в друзья</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="buttons">*/}
                                {/*                    <div className="btn btn-green">Принять</div>*/}
                                {/*                    <div className="btn btn-red">Отклонить</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <div className="item">*/}
                                {/*                <div className="btn-close"></div>*/}
                                {/*                <div className="top">*/}
                                {/*                    <div className="icon green no-fill">*/}
                                {/*                        <svg>*/}
                                {/*                            <use xlinkHref={`${svg}#nn2`}></use>*/}
                                {/*                        </svg>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="date">14 мая 2022 г, 14:17</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="bottom">*/}
                                {/*                    <div className="info">Макс Христовкий добавляет вас в друзья</div>*/}
                                {/*                </div>*/}
                                {/*                <div className="buttons">*/}
                                {/*                    <div className="btn btn-green">Принять</div>*/}
                                {/*                    <div className="btn btn-red">Отклонить</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</OutsideClickHandler>*/}


                                <Modal showModal={showPaymentModal} setShowModal={setShowPaymentModal} wrapperClassName="payment-modal">
                                    <div className="title">Пополнение баланса</div>
                                    <div className="list">
                                        {paymentMethods.map((item, key) => (

                                            <div className={`btn item ${activePaymentMethod === key ? 'active' : ''} ${!item.active ? 'disabled' : ''}`} key={key} onClick={() => setActivePaymentMethod(key)}>
                                                <img src={item.image} alt={item.name}/>
                                                {!item.active ? <CiLock/> : ''}
                                            </div>

                                        ))}
                                    </div>
                                    <div className="bottom">
                                        <div className="form-group">
                                            <input type="number" className="form-control" placeholder="Введите сумму" onChange={event => setAmountPay(event.target.value)}/>
                                            <div className="icon yellow">
                                                <FaRubleSign />
                                            </div>
                                        </div>
                                        <div className="btn btn-green" onClick={donate}>Пополнить баланс</div>
                                    </div>
                                </Modal>
                            </>
                        ) : (
                            <a className="btn btn-steam" href={loginLink}>
                                <div className="icon"><BsSteam/></div>
                                <div className="text">Войти через стим</div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;