import svg from "../../assets/images/trigon.svg";
import {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import {axiosApi, apiDomain} from "../../Api";
import {BsDiscord, BsTelegram, BsTwitch} from "react-icons/bs";
import {SiFaceit} from "react-icons/si";
import {TwitchIcon, VkIcon} from "../UI/СstrigonIcons";
import {useAuth} from "../../contexts/AuthContext";


export default function TabSettings({profile, socials, setSocials}) {

    const { user, setUser } = useAuth();

    //~~~~~~~~~~~~~~~~~~~Functions Edit Profile~~~~~~~~~~~~~~~~~~~~~~~~

    const [inputSuccessUpdate, setInputSuccessUpdate] = useState('');

    const [profileName, setProfileName] = useState('');
    const [profileBirthday, setProfileBirthday] = useState('');
    const [profileAlias, setProfileAlias] = useState('');

    useEffect(() => {

        setProfileName(profile.attributes.name)
        setProfileBirthday(profile.attributes.birthday)
        setProfileAlias(profile.alias || '')

    }, [profile]);

    const inputValidate = (input, success, errorMsg) => {

        if(success) {

            input.classList.add('success')
            setTimeout(function (){
                input.classList.remove('success')
            }, 2000)

        } else {

            input.classList.add('error')
            input.nextSibling.innerText = errorMsg

        }

    }

    const saveProfileData = async (target) => {

        let formData = {},
            name = target.name.split('.'),
            input = document.getElementById(target.name)

        input.classList.add('loading')

        if(name[0] === 'attributes') {
            formData.attributes = {}
            formData.attributes[name[1]] = target.value
        } else formData[name[0]] = target.value

        await axiosApi.post('/user/edit', formData).then((response) => {

            console.log(response)
            if(response.data.success) {

                if(name[0] === 'alias') {

                    const updatedUser = { ...user };
                    updatedUser.alias = target.value;
                    setUser(updatedUser);

                }

                inputValidate(input, true)

            } else {

                inputValidate(input, false, response.data.msg)

            }

        }).catch((error) => {
            console.log(error)
        }).finally((response) => {
            input.classList.remove('loading')
        })

    }

    const delayedOnChange = useCallback(debounce(saveProfileData, 300), [])

    const inputProfileData = (data, setData) => {

        return (event) => {
            document.getElementById(event.target.name).classList.remove('error')
            document.getElementById(event.target.name).nextSibling.innerText = ''
            setData(event.target.value)
            delayedOnChange(event.target)
        }

    }

    //~~~~~~~~~~~~~~~~~~~Socials~~~~~~~~~~~~~~~~~~~~~~~~
    const socialList = [
        {
            type: 'vkontakte',
            title: 'Вконтакте',
            icon: <VkIcon/>
        },
        {
            type: 'telegram',
            title: 'Телеграм',
            icon: <BsTelegram/>
        },
        {
            type: 'discord',
            title: 'Discord',
            icon: <BsDiscord/>
        },
        {
            type: 'twitch',
            title: 'Twitch',
            icon: <TwitchIcon/>
        },
        {
            type: 'faceit',
            title: 'Faceit',
            icon: <SiFaceit/>,
            disable: true
        },
    ]

    const removeSocialProfile = async (type) => {

        await axiosApi.post('/user/remove_social', {type: type}).then((response) => {
            const updatedSocials = { ...socials };
            updatedSocials[type] = null;
            setSocials(updatedSocials);
        }).catch((error) => {
            console.log(error)
        })

    }

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?7";
    script.async = true;
    document.body.appendChild(script);

    const telegramAuth = () => {

        window.Telegram.Login.auth({ bot_id: 5132162939, request_access: true }, async (data) => {

            if(data) {

                const formData = {},
                    telegramUser = {
                        id: data.id,
                        name: data.first_name + ' ' + data.last_name,
                        nickname: data.username,
                        avatar: data.photo_url
                    }

                formData.attributes = {}
                formData.attributes.telegram = JSON.stringify(telegramUser)

                await axiosApi.post('/user/edit', formData).then((response) => {

                    const updatedSocials = {...socials};
                    updatedSocials.telegram = telegramUser;
                    setSocials(updatedSocials);

                }).catch((error) => {
                    console.log(error)
                })

            }

        });
    }

    return (

        <>
            <div className="block">
                <div className="block-title">Аккаунт</div>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Имя</label>
                                <input name="attributes.name" id="attributes.name" type="text" className="form-control" placeholder="Поле не заполнено" value={profileName} onChange={inputProfileData(profileName, setProfileName)} />
                                <div className="error-msg"></div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Дата рождения</label>
                                <input name="attributes.birthday" id="attributes.birthday" type="date" className="form-control" placeholder="Поле не заполнено" value={profileBirthday} onChange={inputProfileData(profileBirthday, setProfileBirthday)}/>
                                <div className="error-msg"></div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Уникальная ссылка профиля</label>
                                <input name="alias" id="alias" type="text" className="form-control" placeholder="Поле не заполнено" value={profileAlias} onChange={inputProfileData(profileAlias, setProfileAlias)}/>
                                <div className="error-msg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block">
                <div className="block-title">Контакты и аккаунт</div>
                <div className="social-settings-list">
                    {socialList.map((item, index) => {
                        return (
                            <div className={`item ${item.type} ${item.disable ? 'block-disable' : ''}`} key={index}>
                                <div className="info">
                                    {item.icon}
                                    <span>{item.title}</span>
                                </div>
                                <div className="bind">
                                    {socials[item.type] ? (
                                        <>
                                            <div className="socialProfile">
                                                {socials[item.type].avatar ? (
                                                    <img src={socials[item.type].avatar} alt=""/>
                                                ) : ''}
                                                {socials[item.type].name ? (
                                                    <span>{socials[item.type].name}</span>
                                                ) : ''}
                                            </div>
                                            <div className="btn btn-red" onClick={() => removeSocialProfile(item.type)}>Открепить</div>
                                        </>
                                    ) : (
                                        <>
                                            <span>Аккаунт не подключен</span>
                                            {item.type === 'telegram' ? (
                                                <div className="btn btn-green" onClick={telegramAuth}>Подключить</div>
                                            ) : (
                                                <a href={`${apiDomain}/auth/social?user_id=${user.id}&type=${item.type}`} className="btn btn-green">Подключить</a>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="block block-disable">
                <div className="block-title">Приватность</div>
                <div className="privacy-settings-list">
                    <div className="item">
                        <div className="name">Кто может смотреть мой инвентарь?</div>
                        <div className="option">
                            <label>Все пользователи</label>
                            <label className="form-checkbox-line">
                                <input type="checkbox"/>
                                <div className="checkbox-line"></div>
                            </label>
                            <label>Только друзья</label>
                        </div>
                    </div>
                    <div className="item">
                        <div className="name">Кто может писать мне личные сообщения?</div>
                        <div className="option">
                            <label>Все пользователи</label>
                            <label className="form-checkbox-line">
                                <input type="checkbox"/>
                                <div className="checkbox-line"></div>
                            </label>
                            <label>Только друзья</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block block-disable">
                <div className="block-title">Пригласи друга</div>
                <div className="banner-block yellow">
                    <div className="background"></div>
                    <div className="title">Мои привилегии</div>
                    <div className="text">Пользователи, зарегистрированные на сайте по вашей ссылке, будут приносить Вам пассивный доход. При пополнении баланса Вашим другом, Вы будете получать целых 10% от суммы его пополнения.</div>
                </div>
                <div className="container user-settings-bottom">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-block referal-link">
                                <label className="form-label">Ссылка для приглашений</label>
                                <input type="text" className="form-control text-start" value="https://cstrigon.ru/?ref=4556" readOnly={true}/>
                                <div className="btn-copy">
                                    <svg>
                                        <use xlinkHref="images/trigon.svg#copy-icon"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-block">
                                <label className="form-label">Приглашенные</label>
                                <div className="btn btn-green">Посмотреть</div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-block">
                                <label className="form-label">Моя прибыль</label>
                                <div className="btn btn-green">Посмотреть</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}