import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import Accordion from 'react-bootstrap/Accordion';

export default function Category({activeWeapon, setActiveWeapon, setSelectedSkins}) {

    const [category, setCategory] = useState({})
    const [activeCategory, setActiveCategory] = useState('')

    useEffect(() => {

        axiosApi.get('/skinchanger/get_category').then(response => {

            if(response.data) {

                setActiveCategory(Object.keys(response.data)[0])

                setActiveWeapon(response.data[Object.keys(response.data)[0]][0])

                setCategory(response.data)

            }

        }).catch(error => {

            console.log('[/skinchanger/get_category]', error)

        })

    }, []);

    const resetSkins = () => {

        axiosApi.post('/skinchanger/reset').then((response) => {

            if(response.data.items) {

                setSelectedSkins(response.data.items)

            }

        }).catch((error) => {
            console.log(error)
        })

    }

    return (


        <div className="category form-block">
            <div className="btn btn-red" onClick={() => {resetSkins()}} style={{padding: '15px', marginBottom: '10px'}}>Сбросить</div>

            {category && activeCategory ? (
                <Accordion defaultActiveKey={activeCategory.toString()}>
                    {Object.keys(category).map(title =>

                        <Accordion.Item eventKey={title} key={title}>
                            <Accordion.Header>{title}</Accordion.Header>
                            <Accordion.Body>
                                {category[title].map(name => {

                                    return (
                                        <div
                                            className={`name ${activeWeapon === name ? 'active' : ''}`}
                                            key={name}
                                            onClick={() => {setActiveWeapon(name)}}
                                        >{name}</div>
                                    )

                                })}
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                </Accordion>

            ) : ''}
        </div>

    )

}