import {AiOutlineFire} from "react-icons/ai";
import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import {Link} from "react-router-dom";

export default function TypesList() {

    const [typeList, setTypeList] = useState([])
    const [typeIsHover, setTypeIsHover] = useState(-1)
    const [loadTypeList, setLoadTypeList] = useState(true)

    useEffect(() => {

        if(loadTypeList) {

            axiosApi.get('/servers/types').then(response => {

                setTypeList(response.data)
                setLoadTypeList(false)

            }).catch(error => {

                console.log('[/servers/types]', error)

            })

        }

    }, [loadTypeList]);

    useEffect(() => {

        setInterval(() => setLoadTypeList(true), 60000)

    }, [])

    return (

        <div className="servers-section">
            <div className="container">

                <div className="title-block">
                    <div className="title">
                        <div className="icon yellow">
                            <AiOutlineFire/>
                        </div>
                        <span>Cписок доступных серверов</span>
                    </div>
                    {/*<div className="btn btn-black btn-border">Фильтр</div>*/}
                </div>

                <div className="type-detail-list">

                    {typeList.map((item, id) => (

                        <div
                            className={`item ${!item.show ? 'disabled' : ''}`}
                            key={id}
                            onMouseEnter={() => { if(item.show) setTypeIsHover(id) }}
                            onMouseLeave={() => setTypeIsHover(-1)}
                        >
                            <img src={` ${typeIsHover === id ? item.gif : item.preview} `} alt={item.name}/>
                            <div className="info">
                                <div className="left">
                                    <div className="title">{item.name}</div>
                                    <div className="online">
                                        <span>{item.players} человек</span>
                                    </div>
                                </div>
                                <div className="rigth">
                                    <Link to="/servers" className="btn btn-purple btn-fill">Играть</Link>
                                </div>
                            </div>
                            {!item.show ? (
                                <div className="disable-text">
                                    <p>Игровой режим</p>
                                    <span>Недоступен</span>
                                </div>
                            ) : ''}
                        </div>

                    ))}

                </div>
            </div>
        </div>

    )

}