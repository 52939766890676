import {createContext, useContext, useEffect, useState} from 'react';
import { axiosApi } from "../Api";

const AuthContent = createContext({
    user: {
        avatar: '',
        nickname: '',
        balance: '',
        alias: ''
    },
    setUser: () => {},
    loginLink: '',
    logout: () => {}
});

export const AuthProvider = ({ children }) => {
    const [user, _setUser] = useState('')

    useEffect(() => {

        const cookies = document.cookie.split('; ').map(cookie => cookie.split('=')).reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {})
        //const storedUser = JSON.parse(localStorage.getItem('user'));

        if(cookies.token) getUser()
        else setUser('')


    }, [])

    const getUser = () => {

        axiosApi.get('/user').then(response => {
            setUser(response.data.user)
        }).catch(error => {
            console.log(error)
        })

    }

    const setUser = (userData) => {

        _setUser(userData)

        // if(userData) localStorage.setItem('user', JSON.stringify(userData))
        // else localStorage.removeItem('user')

    }

    const loginLink = 'https://api.cstrigon.net/steam'

    const logout = () => {

        (async () => {

            setUser('')

            await axiosApi.post('/user/logout').catch(error => {
                console.log('[/user/logout]', error)
            })

        })()

    }

    return (
        <AuthContent.Provider value={{ user, setUser, loginLink, logout }}>
            {children}
        </AuthContent.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContent);
};