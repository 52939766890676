export function VkIcon({background = '#0077FF'}) {

    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.163361 15.36C0.163361 8.11923 0.163361 4.49885 2.46011 2.24942C4.75686 0 8.45342 0 15.8465 0H17.1535C24.5466 0 28.2431 0 30.5399 2.24942C32.8366 4.49885 32.8366 8.11923 32.8366 15.36V16.64C32.8366 23.8808 32.8366 27.5012 30.5399 29.7506C28.2431 32 24.5466 32 17.1535 32H15.8465C8.45342 32 4.75686 32 2.46011 29.7506C0.163361 27.5012 0.163361 23.8808 0.163361 16.64V15.36Z" fill={background}/>
            <path d="M17.5483 23.0534C10.1015 23.0534 5.85402 18.0534 5.67703 9.7334H9.40724C9.52977 15.8401 12.2797 18.4267 14.4579 18.9601V9.7334H17.9704V15C20.1214 14.7734 22.381 12.3734 23.1434 9.7334H26.6559C26.0705 12.9867 23.62 15.3867 21.8774 16.3734C23.62 17.1734 26.411 19.2667 27.4728 23.0534H23.6064C22.7759 20.5201 20.7068 18.5601 17.9704 18.2934V23.0534H17.5483Z" fill="white"/>
        </svg>
    )

}

export function TwitchIcon() {

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39 22.5L33 28.5H27L21.75 33.75V28.5H15V6H39V22.5Z" fill="white"/>
            <path d="M13.5 3L6 10.5V37.5H15V45L22.5 37.5H28.5L42 24V3H13.5ZM39 22.5L33 28.5H27L21.75 33.75V28.5H15V6H39V22.5Z" fill="#9146FF"/>
            <path d="M34.5 11.25H31.5V20.25H34.5V11.25ZM26.25 11.25H23.25V20.25H26.25V11.25Z" fill="#9146FF"/>
        </svg>

    )

}