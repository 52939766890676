import {useState} from "react";
import Types from "./Types";
import List from "./List";

export default function ServersBlock() {

    const [activeType, setActiveType] = useState('')

    return (

        <div className="servers-block-section">

            <Types
                activeType={activeType}
                setActiveType={setActiveType}
            />

            <List
                activeType={activeType}
            />

        </div>

    )


}