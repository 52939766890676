import './css/Shop.scss'
import {FaCartShopping} from "react-icons/fa6";
import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import {IoCheckboxOutline} from "react-icons/io5";
import {CgCloseR} from "react-icons/cg";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {NumericFormat} from "react-number-format";
import {FaCheck} from "react-icons/fa";
import {Triangle} from "react-loader-spinner";
import {useAuth} from "../../contexts/AuthContext";
import Modal from "../../components/UI/Modal";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function Shop() {

    const { user, setUser } = useAuth();

    const [roles, setRoles] = useState([])
    const [activeRole, setActiveRole] = useState(0)
    const [activePrice, setActivePrice] = useState(0)
    const [checkAgree, setCheckAgree] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [orderError, serOrderError] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    useEffect(() => {

        document.title = 'CsTrigon | Магазин';

        axiosApi.get('/shop/roles_list').then(response => {

            setRoles(response.data)

        }).catch(error => {

            console.log('[/shop/roles_list]', error)

        })

    }, [])

    const changeRole = (key) => {

        setActiveRole(key)
        setActivePrice(0)

    }

    const roleOrderHandler = () => {

        const role_price = roles[activeRole].prices[activePrice]

        if(user.balance >= role_price.price) {

            setOrderLoading(true)

            axiosApi.post('/user/order_role', {role_price_id: role_price.id}).then(response => {

                if(response.data.user) setUser(response.data.user)
                setOrderLoading(false)
                console.log(response)

            }).catch(error => {

                console.log('[/user/order_role]', error)
                setOrderLoading(false)

            })

        } else {
            serOrderError('На Вашем балансе не достаточно средств!')

            setTimeout(function (){
                serOrderError('')
            }, 5000)
        }

    }

    const skeletonRoleList = () => {

        let content = [];
        for (let i = 0; i < 2; i++) {
            content.push(
                <div className="btn item">
                    <Skeleton enableAnimation={true} highlightColor={'#272727'} baseColor={'#1f1e1e'} width={70} height={15}/>
                    <span><Skeleton enableAnimation={true} highlightColor={'#272727'} baseColor={'#1f1e1e'} width={50} height={15}/></span>
                </div>
            );
        }

        return content;

    }

    const skeletonPriceList = () => {

        let content = [];
        for (let i = 0; i < 2; i++) {
            content.push(
                <Skeleton containerClassName="item" enableAnimation={true} highlightColor={'#272727'} baseColor={'#1f1e1e'} width={'100%'} height={'100%'} />
            );
        }

        return content;

    }

    return (
        <div className="shop-page">
            <div className="top">
                <div className="container">
                    <div className="row">

                        <div className="col-8">
                            <div className="title-block">
                                <div className="title">
                                    <div className="icon yellow">
                                        <FaCartShopping />
                                    </div>
                                    <span>Магазин</span>
                                </div>
                            </div>

                            <div className="roles-list">

                                {roles.length ? (

                                    roles.map((item, key) => (

                                        <div className={`btn item ${activeRole === key ? 'active' : ''}`} onClick={() => changeRole(key)} key={key}>
                                            {item.name}<span>{item.min_price} руб.</span>
                                        </div>

                                    ))

                                ) : skeletonRoleList()}

                            </div>
                        </div>

                        <div className="col-4 position-relative">
                            <div className="order-wrapper">
                                <div className={`order-section ${orderLoading ? 'loadingAnimation animate' : ''}`}>
                                    <div className="head">
                                        <div className="title">Приобрести привилегию</div>
                                        <div className="prices-list">

                                            {roles[activeRole] ?

                                                roles[activeRole].prices.map((item, key) => (

                                                    <div className={`item ${activePrice === key ? 'active' : ''}`} onClick={() => setActivePrice(key)} key={key}>
                                                        <div className="days">{item.days} дней</div>
                                                        <div className="price"><NumericFormat value={item.price} displayType="text" thousandSeparator=" " /> руб</div>
                                                    </div>

                                                ))

                                                : skeletonPriceList() }
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="agree-block">
                                            <div className={`check ${checkAgree ? 'active' : ''}`} onClick={() => setCheckAgree(!checkAgree)}>
                                                <FaCheck />
                                            </div>
                                            <div className="text">Я согласен с пользовательским соглашением</div>
                                        </div>
                                        <div className={`btn btn-green ${!checkAgree ? 'disabled' : ''}`} onClick={roleOrderHandler}>Оформить</div>

                                        <div className={`error ${orderError ? 'show' : ''}`}>{orderError}</div>
                                    </div>
                                </div>

                                <Triangle
                                    wrapperClass="loader-icon"
                                    height="100"
                                    width="100"
                                    color="#8846ff"
                                    visible={orderLoading}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="content-section">

                            <div className="about" style={{"--role-color": roles[activeRole] ? roles[activeRole].color : '' }}>

                                <div className="banner">
                                    <div className="text">
                                        <div className="name">
                                            {roles[activeRole] ? roles[activeRole].name : ''}
                                        </div>
                                        <div className="slug">CsTrigon</div>
                                    </div>
                                    {roles[activeRole] ? (
                                        <img src={roles[activeRole].image} alt={roles[activeRole].name}/>
                                    ) : ''}
                                </div>

                                {roles[activeRole] ? (
                                    roles[activeRole].description.length ? (

                                        <div className="includes">
                                            <div className="title">Что входит</div>
                                            <div className="list">
                                                {roles[activeRole].description.map((item, key) => (

                                                    <div className={`item ${parseInt(item.include) ? 'green' : 'red'}`} key={key}>
                                                        {parseInt(item.include) ? (
                                                            <IoCheckboxOutline />
                                                        ) : (
                                                            <CgCloseR />
                                                        )}
                                                        <span>{item.text}</span>
                                                    </div>

                                                ))}
                                            </div>
                                        </div>

                                    ) : ''
                                ) : ''}

                                <div className="info">
                                    <IoMdInformationCircleOutline />
                                    <span>Привилегия появится после перезапуска карты</span>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal showModal={showSuccessModal} setShowModal={setShowSuccessModal}>

            </Modal>

        </div>
    )

}