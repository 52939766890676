const Pagination = ({ links, setUrl }) => {

    return (

        <div className="pagination-block">
            <div className="pagination">

                {links.map((item, index) => {

                    if(
                        item.label.indexOf('Previous') === -1 &&
                        item.label.indexOf('Next') === -1 &&
                        item.label.indexOf('previous') === -1 &&
                        item.label.indexOf('next') === -1
                    ) {
                        return (
                            <div className="page-item" key={index} test={item.url}>
                                {item.label === '...' ? (
                                    <span className="page-link">{item.label}</span>
                                ) : (
                                    <a className={`page-link ${item.active ? 'active' : ''}`} onClick={() => setUrl(item.url)}>{item.label}</a>
                                )}
                            </div>
                        )
                    }
                })}


            </div>
        </div>

    )

}

export default Pagination