import './css/Server.scss'
import ServersBlock from "../../components/Server/ServersBlock";
import {useEffect} from "react";

export default function Server() {

    useEffect(() => {

        document.title = 'CsTrigon | Список серверов';

    }, []);

    return (

        <ServersBlock/>
    )

}