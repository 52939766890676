import './css/Profile.scss'

import {useCallback, useEffect, useState} from "react";
import { axiosApi } from '../../Api'
import svg from "../../assets/images/trigon.svg";
import {IoAdd, IoRemove} from "react-icons/io5";
import {TbDiscount2} from "react-icons/tb";
import {AiOutlinePercentage} from "react-icons/ai";
import {MdEdit} from "react-icons/md";
import {useAuth} from "../../contexts/AuthContext";
import {useParams} from "react-router";
import {IoMdCloudUpload} from "react-icons/io";
import {BsDiscord, BsTelegram} from "react-icons/bs";
import Modal from "../../components/UI/Modal";
import {SiSteam} from "react-icons/si";
import {debounce} from "lodash";
import TabInfo from "../../components/Profile/TabInfo";
import TabPrivilege from "../../components/Profile/TabPrivilege";
import TabSettings from "../../components/Profile/TabSettings";
import {SlSocialVkontakte} from "react-icons/sl";
import Inventory from "../../components/Profile/Inventory";

function Profile() {

    const { user} = useAuth();

    useEffect(() => {

        document.title = `CsTrigon | Профиль`;

    }, []);

    const { alias } = useParams()
    const [profile, setProfile] = useState({
        id: '',
        nickname: '',
        avatar: '',
        steamID64: '',
        steam_profile: '',
        registration_date: '',
        balance: 0,
        role: {
            name: '',
            color: ''
        },
        alias: '',
        attributes: {
            email: '',
            birthday: '',
            birthday_format: '',
            banner: '',
        },
        socials: {
            discord: '',
            telegram: '',
            vkontakte: '',
            faceit: '',
            twitch: ''
        }
    })
    const [banner, setBanner] = useState('')
    const [socials, setSocials] = useState({})
    const [showSocials, setShowSocials] = useState(false)
    const [tab, setTab] = useState('profile');

    const [uploadBannerModal, setUploadBannerModal] = useState(false)

    useEffect(() => {

        axiosApi.get('/user/' + alias).then((response) => {

            setProfile(response.data.profile)
            setBanner(response.data.profile.attributes.banner)
            setSocials(response.data.profile.socials)

            let title = 'CsTrigon | '

            if(user) {

                if(response.data.profile.id === user.id) title += 'Мой профиль'

            } else title += 'Профиль игрока '+response.data.profile.nickname;

            document.title = title;

        }).catch((error) => {
            console.log(error)
        })

    }, [alias]);

    useEffect(() => {

        setShowSocials(false)

        Object.values(socials).forEach(value => {
            if(value) setShowSocials(true)
        });

    }, [socials]);

    //~~~~~~~~~~~~~~~~~~~Function Banner~~~~~~~~~~~~~~~~~~~~~~~~

    const [bannerFile, setBannerFile] = useState();

    const uploadBanner = async (action = '') => {

        const formData = new FormData();
        formData.append('banner', bannerFile);
        formData.append('action', action);

        await axiosApi.post('/user/update_banner', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            setBanner(response.data.banner)
            setUploadBannerModal(false)
        }).catch((error) => {
            console.log(error)
        })

    }

    return (
        <div className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="header">
                        <div className="block-banner">
                            <div className="banner">
                                <img src={banner} alt={profile.nickname}/>
                            </div>
                            <div className="avatar">
                                <img src={profile.avatar} alt={profile.nickname}/>
                            </div>
                            {user.id === profile.id ? (
                                <div className="btn btn-banner-upload" onClick={() => {
                                    setUploadBannerModal(true)
                                }}>
                                    <IoMdCloudUpload/>
                                    <span>Изменить шапку</span>
                                </div>
                            ) : ''}
                            {/*<div className="admin-buttons">*/}
                            {/*    <div className="btn btn-yellow tippy-bottom" data-tippy-content="Дать денег">*/}
                            {/*        <IoAdd />*/}
                            {/*    </div>*/}
                            {/*    <div className="btn btn-yellow tippy-bottom" data-tippy-content="Забрать денег">*/}
                            {/*        <IoRemove />*/}
                            {/*    </div>*/}
                            {/*    <div className="btn btn-yellow tippy-bottom" data-tippy-content="Установить скидку">*/}
                            {/*        <AiOutlinePercentage />*/}
                            {/*    </div>*/}
                            {/*    <div className="btn btn-yellow tippy-bottom" data-tippy-content="Редактировать">*/}
                            {/*        <MdEdit />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="main-info">
                            <div className="col-4">
                                {showSocials ? (

                                    <div className="social">
                                        {socials.vkontakte ? (
                                            <a href={`https://vk.com/id${socials.vkontakte.id}`}> <SlSocialVkontakte/> </a>
                                        ) : ''}
                                        {socials.discord ? (
                                            <a href={`https://discord.com/users/${socials.discord.id}`}> <BsDiscord/> </a>
                                        ) : ''}
                                        {socials.telegram ? (
                                            <a href={`https://t.me/${socials.telegram.nickname}`}> <BsTelegram/> </a>
                                        ) : ''}
                                    </div>

                                ) : ''}
                            </div>
                            <div className="col-4">
                                <div className="userinfo">
                                    <div className="role" style={{color: profile.role.color}}>{profile.role.name}</div>
                                    <div className="nickname">{profile.nickname}</div>
                                    {/*{user.id === profile.id ? (*/}
                                    {/*    <div className="balance">{profile.balance} <span className="balance-currency">Руб</span> </div>*/}
                                    {/*) : ''}*/}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="cs-social">
                                    <a href={profile.steam_profile}>
                                        <SiSteam/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="profile-menu">
                            <ul className="nav" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a onClick={() => {setTab('profile')}} className="nav-link active" id="profile" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="true">Профиль</a>
                                </li>

                                {user.id === profile.id ? (
                                    <>
                                        <li className="nav-item" role="presentation">
                                            <a onClick={() => {setTab('privilege')}} className="nav-link" id="privilege-tab" data-bs-toggle="tab" data-bs-target="#privilege-tab-pane" type="button" role="tab" aria-controls="privilege-tab-pane" aria-selected="false">Привилегии и транзакции</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a onClick={() => {setTab('settings')}} className="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane" type="button" role="tab" aria-controls="settings-tab-pane" aria-selected="false">Настройки</a>
                                        </li>
                                    </>
                                ) : ''}

                                <li className="nav-item" role="presentation">
                                    <a onClick={() => {setTab('inventory')}} className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory-tab-pane" type="button" role="tab" aria-controls="inventory-tab-pane" aria-selected="false">Инвентарь</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="body tab-content" id="profileTabContent">

                        <div className="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile" tabIndex="0">
                            <TabInfo profile={profile} tab={tab}/>
                        </div>

                        {user.id === profile.id ? (
                            <>
                                <div className="tab-pane fade" id="privilege-tab-pane" role="tabpanel" aria-labelledby="privilege-tab" tabIndex="0">
                                    <TabPrivilege profile={profile}/>
                                </div>
                                <div className="tab-pane fade" id="settings-tab-pane" role="tabpanel" aria-labelledby="settings-tab" tabIndex="0">
                                    <TabSettings profile={profile} socials={socials} setSocials={setSocials}/>
                                </div>
                            </>
                        ) : ''}

                        <div className="tab-pane fade" id="inventory-tab-pane" role="tabpanel" aria-labelledby="inventory-tab" tabIndex="0">
                            <Inventory profile={profile} tab={tab}/>
                        </div>
                    </div>
                </div>
            </div>


            {user.id === profile.id ? (
                <Modal showModal={uploadBannerModal} setShowModal={setUploadBannerModal}>
                    <div className="upload-banner-modal">
                        <div className="form-group">
                            <label className="form-label">Изображение баннера</label>
                            <input type="file" name="banner" className="form-control" onChange={(e) => setBannerFile(e.target.files[0])}/>
                        </div>
                        <div className="btns">
                            <div className="btn btn-purple" onClick={uploadBanner}>Сохранить</div>
                            <div className="btn btn-red" onClick={() => {uploadBanner('reset')}}>Сбросить</div>
                        </div>
                    </div>

                </Modal>
            ) : ''}
        </div>
    )

}

export default Profile