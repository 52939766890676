import './css/Punishments.scss'

import {useCallback, useEffect, useState} from "react"
import axios from "axios"
import CountUp from 'react-countup'
import List from '../../components/Punishments/List'
import Pagination from "../../components/UI/Pagination"
import { debounce } from 'lodash'
import { apiURL } from '../../Api'


function Punishments() {

    const punishmentURL = apiURL+'/punishments'

    const [list, setList] = useState([])
    const [statesBlock, setStatesBlock] = useState({all: 0, active: 0, permanent: 0, temporary: 0})
    const [pagination, setPagination] = useState([])
    const [url, setUrl] = useState(punishmentURL+'?type=ban')
    const [type, setType] = useState('ban')
    const [loading, setLoading] = useState(true)

    const getPunishmentsList = async (url) => {

        await axios.get(url).then((response) => {
            setList(response.data.data)
            setPagination(response.data.meta.links)
            setStatesBlock(response.data.meta.stats_block)
            setLoading(false)
            //console.log(response.data)
        }).catch((error) => {
            console.log(error)
        })

    }

    useEffect(() => {

        setLoading(true)
        window.scrollTo(0, 0)

        getPunishmentsList(url)

    }, [url])

    const tabChange = (type, url) => {

        setType(type)
        setUrl(url)

    }

    const searchRequest = event => {

        setUrl(punishmentURL + '?type=' + type + '&search=' + event.target.value)

    }

    const debounsSearch = useCallback(
        debounce(searchRequest, 300)
        , [])

    const loadingClasses = () => {

        return 'loadingAnimation ' + (loading ? 'animate' : '')

    }


    const printStatsBlock = () => {

        const blocks = [
            {
                label: 'Всего',
                counter: statesBlock.all
            },
            {
                label: 'Активные',
                counter: statesBlock.active
            },
            {
                label: 'Перманентные',
                counter: statesBlock.permanent
            },
            {
                label: 'Временные',
                counter: statesBlock.temporary
            },
        ]

        return (

            blocks.map(item => (
                <div className={`form-block ${loadingClasses()}`}>
                    <label className="form-label">{item.label}</label>
                    <div className="text text-all-bans" data-text={item.counter}><CountUp separator='' end={item.counter}/></div>
                </div>
            ))

        )


    }

    return (
        <div className="punishments-page">

            <div className="container">

                <div className="panel">

                    <div className="row">

                        <div className="col-5">
                            <div className="nav">
                                <div className={`nav-item ${type === 'ban' ? 'active' : ''}`} onClick={() => tabChange('ban', punishmentURL+'?type=ban')}>
                                    Баны
                                </div>
                                <div className={`nav-item ${type === 'mute' ? 'active' : ''}`} onClick={() => tabChange('mute', punishmentURL+'?type=mute')}>
                                    Муты
                                </div>
                                <div className="nav-item">
                                    Разбаны
                                </div>
                            </div>
                        </div>

                        <div className="col-7">
                            <div className="search-block">
                                <input type="text" name="search" className="form-control" placeholder="Введите никнейм, SteamID или IP" onChange={debounsSearch}/>
                            </div>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-8">
                            <div className="statistics">
                                {printStatsBlock()}
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="appellation">
                                <div className="form-block">
                                    <label className="form-label">Хотите подать апиляцию?</label>
                                    <div className="btn btn-green">Создать заявку</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row">

                    <List data={list} loadingClasses={loadingClasses}/>
                    {url && <Pagination links={pagination} setUrl={setUrl}/>}

                </div>

            </div>


        </div>
    )
}

export default Punishments;