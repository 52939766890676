
import './css/Home.scss'

import svg from '../../assets/images/trigon.svg'
import {GiHamburgerMenu} from "react-icons/gi";
import {BiCheckShield} from "react-icons/bi";
import TypesList from "../../components/Home/TypesList";
import {Link} from "react-router-dom";
import {useEffect} from "react";
export default function Home() {

    useEffect(() => {

        document.title = 'CsTrigon - Комплекс игровых серверов CS 2';

    }, [])

    return (
        <>
            <div className="home-page">

                <div className="top-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="sitename">CsTrigon —<br />игровой проект</div>
                                <div className="siteslogan">
                                    CsTrigon — игровой проект, на котором ты можешь показать свой
                                    скилл, пообщаться с игроками и хорошо провести время
                                </div>
                                <div className="btns">
                                    <Link to="/servers" className="btn btn-purple btn-fill">Перейти к серверам</Link>
                                </div>
                                <div className="siteservers">
                                    <p>Более 100+ серверов</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="news-block">
                                    <div className="news-top">
                                        <div className="title">
                                            <div className="icon purple no-stroke">
                                                <GiHamburgerMenu/>
                                            </div>
                                            Новости
                                        </div>
                                        <div className="verify-icon icon green no-bg">
                                            <BiCheckShield/>
                                        </div>
                                    </div>
                                    <div className="news-list">
                                        <div className="news-item">
                                            <div className="news-item_left">
                                                <div className="image">
                                                    <img src={require("../../assets/images/news-image.png")} alt="" />
                                                </div>
                                                <div className="text">
                                                    Есть желание стать Хелпером? Тогда самое время выдвинуть
                                                    свою кандидатуру!
                                                </div>
                                            </div>
                                            <div className="icon">
                                                <svg>
                                                    <use xlinkHref={`${svg}#vk-logo`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="news-item">
                                            <div className="news-item_left">
                                                <div className="image">
                                                    <img src={require("../../assets/images/news-image.png")} alt="" />
                                                </div>
                                                <div className="text">
                                                    Есть желание стать Хелпером? Тогда самое время выдвинуть
                                                    свою кандидатуру!
                                                </div>
                                            </div>
                                            <div className="icon">
                                                <svg>
                                                    <use xlinkHref={`${svg}#vk-logo`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="news-item">
                                            <div className="news-item_left">
                                                <div className="image">
                                                    <img src={require("../../assets/images/news-image.png")} alt="" />
                                                </div>
                                                <div className="text">
                                                    Есть желание стать Хелпером? Тогда самое время выдвинуть
                                                    свою кандидатуру!
                                                </div>
                                            </div>
                                            <div className="icon">
                                                <svg>
                                                    <use xlinkHref={`${svg}#vk-logo`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TypesList/>

            </div>

            <div className="main-page_landing">
                <div className="container">
                    <div className="title-block">
                        <div className="title">
                            <div className="icon yellow no-fill">
                                <svg>
                                    <use xlinkHref={`${svg}#fire-icon`}></use>
                                </svg>
                            </div>
                            <span>Описание и плюсы проекта</span>
                        </div>
                    </div>
                    <div className="landing-block">
                        <div className="item">
                            <div className="description">
                                <div className="icon red no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#prime-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Не нужен прайм</div>
                                <div className="text">
                                    Для игры на наших серверах, вам не нужно иметь Prime статус
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon red no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#prime-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="description">
                                <div className="icon yellow no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#box-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Внутриигровой инвентарь</div>
                                <div className="text">
                                    Получайте скины после игры, взаимодействуйте с игроками при
                                    помощи торговой площадки, и открывайте кейсы что бы получить
                                    предметы в инветарь.
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon yellow no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#box-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="description">
                                <div className="icon green no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#verify-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Защита сервера</div>
                                <div className="text">
                                    На серверах реализована защита от нечестных игроков
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon green no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#verify-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="description">
                                <div className="icon purple no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#rank-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Система рангов</div>
                                <div className="text">
                                    Получайте очки опыта за хорошую игру, врывайтесь в топ
                                    статистики, и получайте бонусы.
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon purple no-stroke icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#rank-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="description">
                                <div className="icon yellow no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#gift-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Кейсы</div>
                                <div className="text">
                                    Во время игры, вам может упасть кейс в инвентарь Steam'a.
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon yellow no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#gift-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="description">
                                <div className="icon green no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#administrator-icon`}></use>
                                    </svg>
                                </div>
                                <div className="title">Администрация</div>
                                <div className="text">
                                    Всегда готова прийдти к вам на помощь, и разрешить любую
                                    ситуацию.
                                </div>
                            </div>
                            <div className="image">
                                {/* <images src="images/landing.jpg" alt="" /> */}
                                <div className="icon green no-fill icon-border">
                                    <svg>
                                        <use xlinkHref={`${svg}#administrator-icon`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feedbacks-blocks">
                <div className="container">
                    <div className="feedback-block d-flex flex-wrap justify-content-center align-items-center">
                        <div className="title">Возникили вопрсы?</div>
                        <div className="text">
                            Свяжитесь с нашей техподдержкой, они обязательно вам
                            <br />
                            помогут и ответят на все ваши вопросы!
                        </div>
                        <div className="btn btn-white">Поддержка</div>
                    </div>
                    <div className="contact-block d-flex flex-wrap justify-content-center align-items-center">
                        <div className="title">Связь с нами</div>
                        <div className="text">
                            Свяжитесь с нашей техподдержкой, они обязательно вам
                            <br />
                            помогут и ответят на все ваши вопросы!
                        </div>
                        <div className="feedback-btns">
                            <div className="btn btn-black btn-border">
                                <svg>
                                    <use xlinkHref={`${svg}#discord-icon`}></use>
                                </svg>
                                <span>Discord</span>
                            </div>
                            <div className="btn btn-black btn-border">
                                <svg>
                                    <use xlinkHref={`${svg}#vk-icon`}></use>
                                </svg>
                                <span>ВКонтакте</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}