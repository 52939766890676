export default function Modal({showModal, setShowModal, wrapperClassName, children}) {


    return (

        <div className={`modal ${showModal ? 'show' : ''}`} onClick={() => setShowModal(false)}>
            <div className={`dialog ${showModal ? 'show' : ''} ${wrapperClassName}`} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>

    )

}