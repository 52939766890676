import {apiURL, axiosApi} from "../../Api";
import {useAuth} from "../../contexts/AuthContext";
import {useEffect, useState} from "react";
import Pagination from "../UI/Pagination";

export default function Inventory({profile, tab}) {

    const { user } = useAuth();

    const [inventory, setInventory] = useState({})
    const [pagination, setPagination] = useState([])
    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState(apiURL+'/user/inventory/get')

    const [generateRandom, setGenerateRandom] = useState(false)

    const randomSkins = async () => {

        setGenerateRandom(true)

        await axiosApi.post('/user/inventory/random').then(response => {

            console.log(response)

            if(response.status === 200) {
                setLoading(true)
                setGenerateRandom(false)
            }

        }).catch(error => {

            console.log(error)

        })

    }

    useEffect( () => {

        if(tab === 'inventory' && generateRandom === false) {

            (async () => {

                await axiosApi.get(url, {params: {user_id: profile.id}}).then(response => {

                    if(response.data.data) {

                        console.log(response.data.data)

                        setInventory(response.data.data)
                        setPagination(response.data.meta.links)
                        setLoading(false)

                    }

                }).catch(error => {

                    console.log(error)

                })
            })()

        }

    }, [tab, url, generateRandom]);

    return (

        <>
            {user.id === profile.id ? (
                <div className="btn btn-purple w-100 my-3" onClick={randomSkins}>Рандомные скины</div>
            ) : ''}

            <div className={`inventory-list loadingAnimation  ${loading ? 'animate' : '' }`}>

                {!loading ? (

                    <>

                        {inventory.map((item, idx) => {

                            return (

                                <div className={`skin-item ${item.item ? (item.item.rarity) : ''}`} key={idx}>
                                    {item.item ? (
                                        <>
                                            <div className="info">
                                                <div className="weapon">{item.item.weapon}</div>
                                                <div className="separator"></div>
                                                <div className="parameters">
                                                    {item.stattrack ? (
                                                        <div className="stattrack">StatTrak™</div>
                                                    ) : ''}
                                                    <div className="float_title">{item.float_title}</div>
                                                    <div className="float">{item.float}</div>
                                                </div>
                                                <div className="skin">{item.item.skin}</div>
                                            </div>
                                            <div className="image">
                                                <img src={item.item.image}
                                                     alt={`${item.item.weapon} ${item.item.skin}`}/>
                                            </div>
                                        </>

                                    ) : ''}

                                    {item.sticker ? (

                                        <>
                                            <div className="info">
                                                <div className="weapon">Наклейка</div>
                                                <div className="separator"></div>
                                                <div className="skin">{item.sticker.name}</div>
                                            </div>
                                            <div className="image">
                                                <img src={item.sticker.image} alt={item.sticker.name}/>
                                            </div>
                                        </>

                                    ) : ''}
                                </div>

                            )

                        })}

                        {url && <Pagination links={pagination} setUrl={setUrl}/>}

                    </>



                ) : ''}

            </div>
        </>

    )


}