import {useState} from 'react'
import { Link } from 'react-router-dom'
import {CiServer} from "react-icons/ci";
import {IoWarningOutline} from "react-icons/io5";
export default function Navbar() {
    const [activeDropdown, setActiveDropdown] = useState(null);

    const navItems = [
        // {
        //     type: 'link',
        //     text: 'Магазин',
        //     href: '/punishments',
        // },
        // {
        //     type: 'link',
        //     text: 'Архив демо',
        //     href: '/punishments',
        // },
        // {
        //     type: 'link',
        //     text: 'Жалобы',
        //     href: '/punishments',
        // },
        // {
        //     type: 'link',
        //     text: 'Статистика',
        //     href: '/punishments',
        // },
        // {
        //     type: 'link',
        //     text: 'Наказания',
        //     href: '/punishments',
        // },
        {
            type: 'link',
            text: 'Сервера',
            href: '/servers',
            icon: <CiServer />
        },
        {
            type: 'link',
            text: 'Skinchanger',
            href: '/skinchanger',
            icon: <IoWarningOutline />,
            icon_color: '#ffcd00'
        },
        // {
        //     type: 'dropdown',
        //     text: 'Баны',
        //     href: '/bans',
        //     icon: 'images/trigon.svg#menu-burger',
        //     dropdownItems: [
        //         {text: 'Действие', href: '/bans'},
        //         {text: 'Другое действие', href: '/bans'},
        //         {text: 'Что-то еще здесь', href: '/bans'},
        //     ],
        // },
        // {
        //     type: 'dropdown',
        //     text: 'Муты',
        //     href: '/bans',
        //     icon: 'images/trigon.svg#menu-burger',
        //     dropdownItems: [
        //         {text: 'Действие', href: '/bans'},
        //         {text: 'Другое действие', href: '/bans'},
        //         {text: 'Что-то еще здесь', href: '/bans'},
        //         {text: 'Что-то еще здесь', href: '/bans'},
        //         {text: 'Что-то еще здесь', href: '/bans'},
        //     ],
        // },
        // {
        //     type: 'link',
        //     text: 'Контакты',
        //     href: '#',
        // }
    ];

    const toggleDropdown = (index) => {
        if (activeDropdown === index) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(index);
        }
    };

    const renderNavItems = () => {
        return navItems.map((item, index) => {
            switch (item.type) {
                case 'dropdown':
                    return (
                        <li className="nav-item dropdown" key={index}>
                            <div
                                  className="nav-link dropdown-toggle"
                                  onClick={() => toggleDropdown(index)}
                            >
                                {item.text}
                                <svg>
                                    <use xlinkHref={item.icon}></use>
                                </svg>
                            </div>
                            <ul
                                className={`dropdown-menu ${activeDropdown === index ? 'show' : ''}`}
                                aria-labelledby="navbarDropdown"
                                style={
                                    activeDropdown === index
                                        ? {
                                            position: 'absolute',
                                            inset: '0px auto auto 0px',
                                            margin: '0px',
                                            transform: 'translate3d(0px, 40.8px, 0px)'
                                        }
                                        : {}
                                }
                            >
                                {item.dropdownItems.map((dropdownItem, dropdownIndex) => (
                                    <li key={dropdownIndex}>
                                        <Link to={dropdownItem.href} className="dropdown-item">
                                            {dropdownItem.text}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    );

                case 'link':
                    return (
                        <li className="nav-item" key={index}>
                            {item.icon ? (
                                <div className="nav-icon" style={{color: item.icon_color ? item.icon_color : ''}}>
                                    {item.icon}
                                </div>
                            ) : ''}
                            <Link to={item.href} className="nav-link">
                                {item.text}
                            </Link>
                        </li>
                    );

                default:
                    return null;
            }
        });
    };

    return (
        <div className="menu">
            <ul className="nav">
                {renderNavItems()}
            </ul>
        </div>
    )
}