import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import {FaPlay, FaRegCopy} from "react-icons/fa";

export default function List({activeType}) {

    const [servers, setServers] = useState([])
    const [loadServers, setLoadServers] = useState(true)

    useEffect(() => {

        if(loadServers) {

            axiosApi.get('/servers/get', {params: {type: activeType}}).then(response => {

                setServers(response.data)
                setLoadServers(false)

            }).catch(error => {

                console.log('[/servers/get]', error)

            })

        }

    }, [activeType])

    useEffect(() => {

        setInterval(() => setLoadServers(true), 60000)

    }, [])

    return (

        <div className="servers-list-section">
            <div className="container">
                <div className="list">
                    {servers.map((item, key) => (

                        <div className={`item ${item.status === 'online' ? 'map-'+item.info.map : ''}`} key={key}>
                            <div className="image">
                                <img src={item.status === 'online' ? require('../../assets/images/maps/'+item.info.map+'.jpg') : require('../../assets/images/logo.png')} alt=""/>
                            </div>
                            <div className="description">
                                <div className="top">
                                    <div className="map">Dust 2</div>
                                    <div className="btn-copy" onClick={() => {navigator.clipboard.writeText('connect '+item.ip+':'+item.port)}}>
                                        <FaRegCopy />
                                    </div>
                                    {item.status === 'online' ? (
                                        <div className="online">{item.info.players.now}/{item.info.players.max}</div>
                                    ) : (
                                        <div className="offline"></div>
                                    )}
                                </div>
                                <div className="name">{item.name}</div>
                                <a href={`steam://connect/${item.ip}:${item.port}`} className="btn-play btn btn-purple">
                                    <FaPlay />
                                </a>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>

    )

}