import React from 'react';
import {Outlet, RouterProvider} from 'react-router-dom';
import Header from "../components/Header/Header";
import router from "../router";
import Footer from "../components/Footer/Footer";

export default function GuestLayout() {

    return (
        <>
            <Header/>
                <Outlet />
            <Footer/>
        </>
    );

}