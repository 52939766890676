
export default function TabInfo({profile}) {

    return (
        <>
            <div className="block">
                <div className="block-title">Информация</div>
                <div className="profile-user-info">
                    <div className="col-md-6">
                        <span>Номер профиля:</span>{profile.id}
                    </div>
                    <div className="col-md-6">
                        <span>Имя:</span>{profile.attributes.name ?? '-'}
                    </div>
                    <div className="col-md-6">
                        <span>Дата рождения:</span>{profile.attributes.birthday_format ?? '-'}
                    </div>
                    <div className="col-md-6">
                        <span>Дата регистрации</span>{profile.registration_date}
                    </div>
                </div>
            </div>
            {/*<div className="block">*/}
            {/*    <div className="block-title">Заявки на разбан</div>*/}
            {/*    <div className="unban-applications-list table-block table-row-equal">*/}
            {/*        <div className="unban-applications-item">*/}
            {/*            <div className="nickname">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>V Λ C U U M</span>*/}
            {/*            </div>*/}
            {/*            <div className="rule">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#rule-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>Правило 2.1</span>*/}
            {/*            </div>*/}
            {/*            <div className="date">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#clock-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>28 мая 2022 г, 18:45</span>*/}
            {/*            </div>*/}
            {/*            <div className="admin">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-check-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>SKYM4N</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="unban-applications-item">*/}
            {/*            <div className="nickname">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>V Λ C U U M</span>*/}
            {/*            </div>*/}
            {/*            <div className="rule">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#rule-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>Правило 2.1</span>*/}
            {/*            </div>*/}
            {/*            <div className="date">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#clock-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>28 мая 2022 г, 18:45</span>*/}
            {/*            </div>*/}
            {/*            <div className="admin">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-check-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>SKYM4N</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="unban-applications-item">*/}
            {/*            <div className="nickname">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>V Λ C U U M</span>*/}
            {/*            </div>*/}
            {/*            <div className="rule">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#rule-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>Правило 2.1</span>*/}
            {/*            </div>*/}
            {/*            <div className="date">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#clock-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>28 мая 2022 г, 18:45</span>*/}
            {/*            </div>*/}
            {/*            <div className="admin">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-check-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>SKYM4N</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="unban-applications-item">*/}
            {/*            <div className="nickname">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>V Λ C U U M</span>*/}
            {/*            </div>*/}
            {/*            <div className="rule">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#rule-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>Правило 2.1</span>*/}
            {/*            </div>*/}
            {/*            <div className="date">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#clock-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>28 мая 2022 г, 18:45</span>*/}
            {/*            </div>*/}
            {/*            <div className="admin">*/}
            {/*                <div className="icon grey no-fill">*/}
            {/*                    <svg>*/}
            {/*                        <use xlinkHref="images/trigon.svg#user-check-icon"></use>*/}
            {/*                    </svg>*/}
            {/*                </div>*/}
            {/*                <span>SKYM4N</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="btn btn-yellow btn-show-all-unbans">Отобразить все заявки</div>*/}
            {/*</div>*/}
        </>
    )

}