import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './assets/fonts/Gilroy.css';
import './assets/css/general.scss'
import './assets/css/style.scss';

import { RouterProvider } from 'react-router-dom';
import router from './router';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
