import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import PopupWeapon from "./PopupWeapon";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import t_patch from "../../assets/images/t_patch.png";
import ct_patch from "../../assets/images/ct_patch.png";

export default function MainBlock({activeWeapon, selectedSkins, setSelectedSkins}) {

    const [weaponList, setWeaponList] = useState([])
    const [weaponID, setWeaponID] = useState(0)
    const [showPopup, setShowPopup] = useState(false)
    const [currentWeaponsData, setCurrentWeaponsData] = useState([])
    const [loadingWeapons, setLoadingWeapons] = useState(false)

    useEffect(() => {

        if(activeWeapon) {

            setWeaponID(0)
            setLoadingWeapons(true)

            axiosApi.get('/skinchanger/get_weapon_list_by_name', {params: {name: activeWeapon}}).then(response => {

                if(response.data.data) {

                    setWeaponList(response.data.data)

                    let temp = {}

                    response.data.data.map(item => {
                        temp[item.id] = item
                    })

                    setCurrentWeaponsData(temp)

                    setLoadingWeapons(false)

                }

            }).catch(error => {

                console.log('[/skinchanger/get_weapon_list_by_name]', error)

            })

        }

    }, [activeWeapon]);

    const popupHandler = (id) => {

        setWeaponID(id)
        setShowPopup(true)

    }

    const skeletonLoader = () => {

        let content = [];
        for (let i = 0; i < 20; i++) {
            content.push(
                <div className="skin-item" key={i}>
                    <div className="info">
                        <div className="weapon"><Skeleton enableAnimation={true} highlightColor={'#414141'} baseColor={'#333333'} width={'30%'}/></div>
                        <div className="separator"></div>
                        <div className="skin"><Skeleton enableAnimation={true} highlightColor={'#414141'} baseColor={'#333333'} width={'100%'}/></div>
                    </div>
                    <div className="image"><Skeleton enableAnimation={true} highlightColor={'#414141'} baseColor={'#333333'} width={'100%'} height={147}/></div>
                </div>
            );
        }

        return content;

    }

    return (

        <div className="main">

            {weaponList.length && !loadingWeapons ? (

                <>

                    {weaponList.map((item, key) => {

                        return (

                            <div className={`skin-item ${item.rarity} ${selectedSkins[item.id] ? 'selected' : ''}`} key={key} onClick={() => {popupHandler(item.id)}}>
                                <div className="info">
                                    <div className="weapon">{item.weapon}</div>
                                    <div className="separator"></div>
                                    <div className="skin">{item.skin}</div>
                                </div>
                                <div className="image">
                                    <img src={item.image}
                                         alt={`${item.weapon} ${item.skin}`}/>
                                </div>
                                {selectedSkins[item.id] ? (

                                    <>
                                        {selectedSkins[item.id].side ? (
                                            <div className="side">
                                                <img src={selectedSkins[item.id].side === 2 ? t_patch : ct_patch}/>
                                            </div>
                                        ) : ''}

                                        {selectedSkins[item.id].stickers ? (

                                            <div className="stickers">
                                                {Array.isArray(selectedSkins[item.id].stickers) ?

                                                    selectedSkins[item.id].stickers.map(sticker => (
                                                            <img src={sticker.image} alt=""/>
                                                        ))

                                                    :

                                                    Object.keys(selectedSkins[item.id].stickers).map(key => (
                                                        <img src={selectedSkins[item.id].stickers[key].image} alt=""/>
                                                    ))
                                                }

                                            </div>

                                        ) : ''}
                                    </>


                                ) : ''}
                            </div>

                        )

                    })}

                    <PopupWeapon
                        showPopup={showPopup}
                        setShowPopup={setShowPopup}
                        weaponID={weaponID}
                        activeWeapon={activeWeapon}
                        currentWeaponsData={currentWeaponsData}
                        selectedSkins={selectedSkins}
                        setSelectedSkins={setSelectedSkins}
                    />

                </>

            ) : skeletonLoader()}

        </div>

    )

}