import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import {CiLock} from "react-icons/ci";
import {AiOutlineFire} from "react-icons/ai";

export default function Types({activeType, setActiveType}) {

    const [typeList, setTypeList] = useState([])

    useEffect(() => {

        axiosApi.get('/servers/types').then(response => {

            setTypeList(response.data)

            response.data.some(item => {
                if(item.show) {
                    setActiveType(item.name)
                    return true;
                }
            })

        }).catch(error => {

            console.log('[/servers/types]', error)

        })

    }, []);

    return (
        <div className="type-filter-section">
            <div className="container">

                <div className="title-block">
                    <div className="title">
                        <div className="icon yellow">
                            <AiOutlineFire/>
                        </div>
                        <span>Cписок доступных серверов</span>
                    </div>
                    {/*<div className="btn btn-black btn-border">Фильтр</div>*/}
                </div>

                <div className="list">
                    {typeList.map((item, id) => (

                        <div className={`btn ${activeType === item.name ? 'active' : ''} ${!item.show ? 'disabled' : ''}`} key={id} onClick={() => setActiveType(item.name)}>
                            {!item.show ? (<CiLock/>) : ''}
                            <div className="text">{item.name}</div>
                        </div>

                    ))}
                </div>

            </div>
        </div>
    )

}