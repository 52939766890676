import { createBrowserRouter } from 'react-router-dom';


import ProtectedLayout from './layouts/ProtectedLayout';
import GuestLayout from './layouts/GuestLayout';

import Home from "./pages/Home/Home";
import Punishments from "./pages/Punishments/Punishments";
import Profile from "./pages/Profile/Profile";
import Skinchanger from "./pages/Skinchanger/Skinchanger";
import Server from "./pages/Server/Server";
import Shop from "./pages/Shop/Shop";

const router = createBrowserRouter([
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/punishments',
                element: <Punishments />,
            },
            {
                path: '/profile/:alias',
                element: <Profile />,
            },
            {
                path: '/skinchanger',
                element: <Skinchanger />,
            },
            {
                path: '/servers',
                element: <Server/>
            },
            {
                path: '/shop',
                element: <Shop/>
            }
        ],
    },
    // {
    //     path: '/',
    //     element: <ProtectedLayout />,
    //     children: [
    //         {
    //             path: '/profile/:alias',
    //             element: <Profile />,
    //         },
    //     ],
    // },
]);

export default router;