import './css/Footer.scss'
import svg from '../../assets/images/trigon.svg';

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="top row">
                    <div className="col-md-6">
                        <div className="sitename">CsTrigon — <br/> игровой проект</div>
                    </div>
                    <div className="col-md-6 text-end">
                        <a href="#" className="link-servers">Сервера</a>
                    </div>
                </div>
                <div className="bottom row">
                    <div className="col-md-4">
                        <ul className="nav flex-column">
                            <div className="title">Навигация</div>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Архив демо</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Жалобы</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Статистика</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Форум</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Баны</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="nav flex-column">
                            <div className="title">Другое</div>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Стать админом</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Стать админом</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Стать админом</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Стать админом</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <div className="social">
                            <div className="title">Подпишись на нас</div>
                            <div className="links">
                                <a href="#">
                                    <svg>
                                        <use xlinkHref={`${svg}#vk-icon`}></use>
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg>
                                        <use xlinkHref={`${svg}#telegram-icon`}></use>
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg>
                                        <use xlinkHref={`${svg}#discord-icon`}></use>
                                    </svg>
                                </a>
                                <a href="#">
                                    <svg>
                                        <use xlinkHref={`${svg}#youtube-icon`}></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-6">
                            <div className="text">© 2023 CsTrigon | Development</div>
                        </div>
                        <div className="col-6 text-end">
                            <div className="text">Developed by <span>VAZKON</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;