import {useEffect, useState} from "react";
import t_patch from '../../assets/images/t_patch.png'
import ct_patch from '../../assets/images/ct_patch.png'
import PopupStickers from "./PopupStickers";
import {axiosApi} from "../../Api";
import {WeaponStickersPosition} from "../UI/WeaponStickersPosition";

export default function PopupWeapon({showPopup, setShowPopup, weaponID, activeWeapon, currentWeaponsData, selectedSkins, setSelectedSkins}) {

    const [showPopupStickers, setShowPopupStickers] = useState(false)
    const [stickersList_height, setStickersList_height] = useState(0)

    useEffect( () => {

        if(showPopup) {

            setTimeout(function (){
                setStickersList_height(document.getElementById('skinchanger-popup-item').getBoundingClientRect().height)
            }, 100)

        }

    }, [showPopup]);

    const [item, setItem] = useState({})
    const [floatTitle, setFloatTitle] = useState('Закаленное в боях')
    const [floatBarValueClass, setFloatBarValueClass] = useState('')
    const [image, setImage] = useState('')

    const [float, setFloat] = useState(0.0001)
    const [seed, setSeed] = useState(0)
    const [nametag, setNametag] = useState('')
    const [side, setSide] = useState(0)
    const [stattrak, setStattrak] = useState(false)
    const [stattrakCount, setStattrakCount] = useState(0)
    const [stickers, setStickers] = useState([])

    const [stickersList, setStickersList] = useState([])
    const [activeSticker, setActiveSticker] = useState(0)
    const [showStickersPosition, setShowStickersPosition] = useState(false)

    useEffect( () => {

        if(weaponID > 0) setItem(currentWeaponsData[weaponID])

    }, [weaponID]);

    useEffect( () => {

        if(Object.keys(item).length > 0) {

            if(selectedSkins[weaponID]) {

                floatHandler(selectedSkins[weaponID].float)
                setSeed(selectedSkins[weaponID].seed)
                setNametag(selectedSkins[weaponID].nametag)
                setSide(selectedSkins[weaponID].side)
                setStickers(selectedSkins[weaponID].stickers)

            } else {

                setSeed(0)
                setNametag('')
                setSide(weaponSides[item.weapon_title] ? weaponSides[item.weapon_title] : 0)
                setStickers([])
                floatHandler()

            }

        }

    }, [item]);


    const floatHandler = (value) => {

        let thisFloat = (value || 0.0001)

        if(thisFloat < 0.0001) thisFloat = 0.0001
        if(thisFloat > 0.9999) thisFloat = 0.9999

        setFloat(thisFloat)

        if(thisFloat <= 0.07) {
            setFloatTitle('Прямо с завода')
            setFloatBarValueClass('fn')
        }
        else if(thisFloat > 0.07 && thisFloat <= 0.15) {
            setFloatTitle('Немного поношенное')
            setFloatBarValueClass('mw')
        }
        else if(thisFloat > 0.15 && thisFloat <= 0.37) {
            setFloatTitle('После полевых испытаний')
            setFloatBarValueClass('ft')
        }
        else if(thisFloat > 0.38 && thisFloat <= 0.45) {
            setFloatTitle('Поношенное')
            setFloatBarValueClass('ww')
        }
        else {
            setFloatTitle('Закаленное в боях')
            setFloatBarValueClass('bs')
        }

        if(item.patterns) {

            let closest = item.patterns.reduce(function(prev, curr) {
                return (Math.abs(curr.float - thisFloat) < Math.abs(prev.float - thisFloat) ? curr : prev);
            });

            setImage(closest.image)

        }

    }

    // const seedHandler = (event) => {
    //
    //     setSeed(event.target.value)
    //
    // }

    const floatBarHandler = () => {

        if(Object.keys(item).length > 0) {

            let styles = {
                fn: {
                    count: 0,
                    percent: 0
                },
                mw: {
                    count: 0,
                    percent: 0
                },
                ft: {
                    count: 0,
                    percent: 0
                },
                ww: {
                    count: 0,
                    percent: 0
                },
                bs: {
                    count: 0,
                    percent: 0
                }
            }

            item.patterns.map(item => {

                if(item.float <= 0.07) styles.fn.count++
                else if(item.float > 0.07 && item.float <= 0.15) styles.mw.count++
                else if(item.float > 0.15 && item.float <= 0.37) styles.ft.count++
                else if(item.float > 0.38 && item.float <= 0.45) styles.ww.count++
                else styles.bs.count++

            })

            let prev = 0

            for(let key in styles) {

                styles[key].percent = 100 / (Object.keys(item.patterns).length / styles[key].count) + prev
                prev = styles[key].percent

            }

            let left = 100 / (0.9999 / float)

            if(!Number.isInteger(left)) left = left.toFixed(2)

            return (

                <>
                    <div className="fn" style={{width: styles.fn.percent + '%'}}/>
                    <div className="mw" style={{width: styles.mw.percent + '%'}}/>
                    <div className="ft" style={{width: styles.ft.percent + '%'}}/>
                    <div className="ww" style={{width: styles.ww.percent + '%'}}/>
                    <div className="bs" style={{width: styles.bs.percent + '%'}}/>
                    <div className={`value ${floatBarValueClass}`} style={{left: left + '%'}}>{left + '%'}</div>
                </>

            )

        }

    }

    const sideHandler = (value) => {

        if(side === value) setSide(0)
        else setSide(value)

    }

    const stickerPopupHandler = () => {

        setShowPopupStickers(!showPopupStickers)
        if(showPopupStickers) {
            setActiveSticker(0)
            setShowStickersPosition(false)
        }

    }

    const stickersSetHandler = (position, action) => {

        const tempStickers = {...stickers}
        if(action === 'set') tempStickers[position] = stickersList[activeSticker]
        else if(action === 'remove') delete(tempStickers[position])
        setStickers(tempStickers)

    }

    const setSkinHandler = () => {

        axiosApi.post('/skinchanger/set', {
            data: {
                skin_id: weaponID,
                dev_weapon_title: (currentWeaponsData[weaponID].type === 'knife' ? 'knife' : currentWeaponsData[weaponID].weapon_title),
                float: float,
                seed: seed,
                nametag: nametag,
                side: side,
                stickers: stickers,
                stattrak: stattrak,
                stattrak_count: stattrakCount,
                type: currentWeaponsData[weaponID].type
            }
        }).then((response) => {

            if(response.data.items) {

                setSelectedSkins(response.data.items)
                setShowPopup(false)

            }

        }).catch((error) => {
            console.log(error)
        })



        // const tempSelectedSkins = {...selectedSkins}
        // tempSelectedSkins[weaponID] = {
        //     skin_id: weaponID,
        //     float: float,
        //     seed: seed,
        //     nametag: nametag,
        //     side: side,
        //     stickers: stickers,
        //     stattrak: false
        // }
        //
        // setSelectedSkins(tempSelectedSkins)
        // setShowPopup(false)

    }

    const weaponSides = {
        'mac10': 2,
        'mp9': 3,
        'ak47': 2,
        'aug': 3,
        'famas': 3,
        'galilar': 2,
        'm4a1_silencer': 3,
        'm4a1': 3,
        'sg556': 2,
        'g3sg1': 2,
        'scar20': 3,
        'mag7': 3,
        'sawedoff': 2,
        'cz75a': 3,
        'fiveseven': 3,
        'glock': 2,
        'hkp2000': 3,
        'tec9': 2,
        'usp_silencer': 3,
        'bizon': 3,
        'elite': 2
    }

    return (

        <div className={`modal skinchanger-popup ${showPopup ? 'show' : ''} ${showPopupStickers ? 'stickers-show' : ''}`} onClick={() => setShowPopup(false)}>
            <div className={`dialog ${showPopup ? 'show' : ''}`} onClick={e => e.stopPropagation()}>

                {item ? (

                    <>

                        <PopupStickers
                            showPopupStickers={showPopupStickers}
                            stickersList_height={stickersList_height}
                            stickersList={stickersList}
                            setStickersList={setStickersList}
                            setShowStickersPosition={setShowStickersPosition}
                            activeSticker={activeSticker}
                            setActiveSticker={setActiveSticker}
                        />

                        <div id="skinchanger-popup-item" className={`item-weapon`}>
                            <div className={`image ${showStickersPosition ? 'show-stickers-btns' : ''} ${item.weapon_title} ${item.type}`}>
                                <img src={image} alt=""/>
                                <div className="stickers-sets">
                                    {WeaponStickersPosition[item.weapon_title] ?

                                        WeaponStickersPosition[item.weapon_title].map((position, key) => (
                                            <div className={`position ${!stickers[key] ? 'set' : ''}`} key={key} style={{top: position.top + '%', left: position.left + '%', transform: 'rotate('+(position.rotate ? position.rotate : 0)+'deg)'}}>
                                                {stickers[key] ? (
                                                    <div className="current">
                                                        <img src={stickers[key].image} alt=""/>
                                                        <div className="remove-btn" onClick={() => {stickersSetHandler(key, 'remove')}}>-</div>
                                                    </div>
                                                ) : ''}
                                                <div className="set-btn" onClick={() => {stickersSetHandler(key, 'set')}}>+</div>
                                            </div>
                                        ))

                                    : ''}
                                </div>
                            </div>
                            <div className="info">
                                <div className="top">
                                    <div className="weapon">{item.weapon}</div>
                                    <div className="skin">{item.skin}</div>
                                </div>
                                <div className="separator"></div>
                                <div className="float-bar">
                                    {floatBarHandler()}
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="float-title">{floatTitle}</div> |
                                    <div className="seed"><span>{seed}</span></div>
                                </div>
                                <div className="separator"></div>
                                <div className="form-group">
                                    <label>Выберите Float</label>
                                    <div className="range-block">
                                        <input className="form-control" type="number" step="0.0001" min="0.0001" max="0.9999" value={float} onChange={event => {floatHandler(event.target.value)}}/>
                                        <input type="range" step="0.0001" min="0.0001" max="0.9999" value={float} onChange={event => {floatHandler(event.target.value)}}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Выберите паттерн</label>
                                    <div className="range-block">
                                        <input className="form-control" type="number" step="1" min="0" max="1000" value={seed} onChange={event => {setSeed(event.target.value)}}/>
                                        <input type="range" step="1" min="0" max="1000" value={seed} onChange={event => {setSeed(event.target.value)}}/>
                                    </div>
                                </div>
                                <div className="separator"></div>
                                {/*<div className="form-group">*/}
                                {/*    <div className="row" style={{margin: '0 -10px'}}>*/}
                                {/*        <div className="col-6">*/}
                                {/*            <label>STATTRACK</label>*/}
                                {/*            <div className="stattrack-block">*/}
                                {/*                <input type="checkbox" className="form-check" checked={stattrak} onChange={() => {setStattrak(!stattrak)}}/>*/}
                                {/*                <input className="form-control" type="number" value={stattrakCount} disabled={!stattrak} onChange={event => {setStattrakCount(event.target.value)}} />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-6">*/}
                                {/*            <label>Неймтег</label>*/}
                                {/*            <input type="text" name="nametag" className="form-control" placeholder="Неймтег" value={nametag} onChange={event => {setNametag(event.target.value)}}/>*/}

                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*</div>*/}
                                <div className="bottom">

                                    {item.type === 'weapon' ? <div className="btn btn-black-4" onClick={stickerPopupHandler}>Наклейка</div> : ''}

                                    <div className="side-check">

                                        {!weaponSides[item.weapon_title] ? (

                                            <>
                                                <div className={`t ${side === 2 ? 'active' : ''}`} onClick={() => {sideHandler(2)}}>
                                                    <img src={t_patch} alt="t"/>
                                                </div>
                                                <div className={`ct ${side === 3 ? 'active' : ''}`} onClick={() => {sideHandler(3)}}>
                                                    <img src={ct_patch} alt="ct"/>
                                                </div>
                                            </>

                                        ) : ''}




                                    </div>
                                    <div className="btn btn-purple" onClick={setSkinHandler}>Установить</div>
                                </div>
                            </div>
                        </div>

                    </>

                ) : ''}


            </div>
        </div>

    )

}