import {useCallback, useEffect, useRef, useState} from "react";
import {axiosApi} from "../../Api";
import {LineWave} from "react-loader-spinner";
import {BsSearch} from "react-icons/bs";
import {AiOutlineClose} from "react-icons/ai";
import {debounce} from "lodash";

export default function PopupStickers({showPopupStickers, stickersList_height, stickersList, setStickersList, setShowStickersPosition, activeSticker, setActiveSticker}){

    const listInnerRef = useRef()
    const [currPage, setCurrPage] = useState(1)
    const [prevPage, setPrevPage] = useState(0)
    // const [list, setList] = useState([])
    const [lastList, setLastList] = useState(false)

    const [search, setSearch] = useState('')
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {

        const getStickersList = async () => {

            const response = await axiosApi.get(`/skinchanger/get_stickers_list?page=${currPage}&search=${search}`)
            if (!response.data.data.length) {
                setLastList(true)
                return
            }
            setPrevPage(currPage)

            let temp = []

            response.data.data.map(item => {
                temp[item.id] = item
            })

            if((search && currPage === 1) || currPage === 1) setStickersList(temp)
            else setStickersList([...stickersList, ...temp])

            if(currPage === response.data.last_page) setLastList(true)
        }

        if (!lastList && prevPage !== currPage) {
            getStickersList()
        }

    }, [currPage, search])



    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if ((scrollTop + clientHeight) + 1 >= scrollHeight) {
                setCurrPage(currPage + 1)
                console.log('onScroll', search, currPage)
            }
        }
    }

    const searchRequest = event => {

        setCurrPage(1)
        setPrevPage(0)
        setLastList(false)
        setSearch(event.target.value)

    }

    const debounceSearch = useCallback(
        debounce(searchRequest, 300)
        , [])


    const stickerPositionHandler = (id) => {

        if(activeSticker === id) {
            setActiveSticker(0)
            setShowStickersPosition(false)
        }
        else {
            setActiveSticker(id)
            setShowStickersPosition(true)
        }

    }

    return (

        <div className={`stickers ${showPopupStickers ? 'show' : ''}`}>
            <div className="block-title">
                <span>Стикеры</span>
                <div className="search">
                    <input type="text" className={`form-control ${showSearch ? 'show' : ''}`} placeholder="Поиск" onChange={debounceSearch}/>
                    <div className={`show-input ${showSearch ? 'hide' : ''}`} onClick={() => {setShowSearch(true)}}>
                        <BsSearch/>
                    </div>
                    <div className={`hide-input ${!showSearch ? 'hide' : ''}`} onClick={() => {setShowSearch(false)}}>
                        <AiOutlineClose/>
                    </div>
                </div>
            </div>
            <div className="separator"></div>

            <div id="stickers-popup-list" className="list" style={{maxHeight: (stickersList_height - 110) + 'px'}} onScroll={onScroll} ref={listInnerRef}>
                {stickersList.map((item, id) =>

                    item ? (
                        <div className={`item ${activeSticker === id ? 'active' : ''}`} key={id} onClick={() => {stickerPositionHandler(id)}}>
                            <div className="image">
                                <img src={item.image} alt={item.name}/>
                            </div>
                            <div className="name">{item.name}</div>
                        </div>
                    ) : ''

                )}
                {!lastList ? (
                    <LineWave
                        width="120"
                        height="120"
                        wrapperClass="loader"
                        firstLineColor="#8846FF59"
                        middleLineColor="#8846FFEF"
                        lastLineColor="#8846FF98"
                    />
                ) : ''}
            </div>
        </div>

    )

}