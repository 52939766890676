import svg from "../../assets/images/trigon.svg";

const getAvatar = (avatar) => {

    if(avatar.length !== 0) return (<img src={avatar} alt=""/> )
    else return (<svg><use xlinkHref={`${svg}#user-icon`}></use></svg> )

}

const List = ({ data, loadingClasses }) => {

    return (
        <div className={`list-table ${loadingClasses()}`}>
            <div className="head">
                <div>Игрок</div>
                <div>Правило</div>
                <div>Тип</div>
                <div>Окончание</div>
                <div>Админ</div>
            </div>

            <div className="table-block table-row-equal">

                {data.map((item, index) => (

                    <div className="item" key={index}>
                        <div className="nickname">
                            <div className={`icon ${item.player.avatar ? 'avatar' : 'grey no-fill'}`}> {getAvatar(item.player.avatar)} </div>
                            <span>{item.player.nickname}</span>
                        </div>
                        <div className="rule">
                            <div className="icon grey no-fill">
                                <svg>
                                    <use xlinkHref={`${svg}#rule-icon`}></use>
                                </svg>
                            </div>
                            <span>{item.rule}</span>
                        </div>
                        <div className="type mute">
                            <div className="icon grey">
                                <svg>
                                    <use xlinkHref={`${svg}#mute-icon`}></use>
                                </svg>
                            </div>
                            <span>{item.type}</span>
                        </div>
                        <div className={`date ${(item.end === 'permanent' || item.end === 'timeout') ? item.end : ''}`}>
                            <div className="icon grey no-fill">
                                <svg>
                                    <use xlinkHref={`${svg}#clock-icon`}></use>
                                </svg>
                            </div>
                            <span>{item.end === 'permanent' ? 'Навсегда' : item.end === 'timeout' ? 'Срок истек' : item.end}</span>
                        </div>
                        <div className="admin">
                            <div className={`icon ${item.admin.avatar ? 'avatar' : 'grey no-fill'}`}> {getAvatar(item.admin.avatar)} </div>
                            <span>{item.admin.nickname}</span>
                        </div>
                    </div>

                ))}



            </div>
        </div>
    )

}

export default List