const WeaponStickersPosition = {
    'ak47': [
        {top: 33, left: 31},
        {top: 34, left: 44},
        {top: 35, left: 51},
        {top: 34, left: 60}
    ],
    'aug': [
        {top: 32, left: 43},
        {top: 32, left: 54},
        {top: 33, left: 64},
        {top: 36, left: 79}
    ],
    'famas': [
        {top: 36, left: 33},
        {top: 36, left: 44},
        {top: 28, left: 52},
        {top: 39, left: 64}
    ],
    'galilar': [
        {top: 31, left: 36},
        {top: 33, left: 49},
        {top: 32, left: 58},
        {top: 37, left: 79}
    ],
    'm4a1_silencer': [
        {top: 35, left: 42},
        {top: 43, left: 50.5},
        {top: 37, left: 56},
        {top: 36, left: 62}
    ],
    'm4a1': [
        {top: 47, left: 42.5},
        {top: 35, left: 48},
        {top: 42, left: 54},
        {top: 36, left: 79}
    ],
    'sg556': [
        {top: 27, left: 54},
        {top: 48, left: 50.5},
        {top: 41, left: 57},
        {top: 40, left: 64}
    ],
    'mag7': [
        {top: 29, left: 43},
        {top: 32, left: 51},
        {top: 40, left: 64},
        {top: 26, left: 74}
    ],
    'nova': [
        {top: 34, left: 44},
        {top: 34, left: 50},
        {top: 35, left: 56},
        {top: 23, left: 61}
    ],
    'sawedoff': [
        {top: 44, left: 28},
        {top: 41, left: 51},
        {top: 42, left: 60},
        {top: 41, left: 68}
    ],
    'xm1014': [
        {top: 37, left: 38},
        {top: 35, left: 45},
        {top: 35, left: 51},
        {top: 36, left: 57}
    ],
    'cz75a': [
        {top: 23, left: 46},
        {top: 25, left: 53},
        {top: 33, left: 59},
        {top: 50, left: 63}
    ],
    'deagle': [
        {top: 26, left: 36},
        {top: 26, left: 50},
        {top: 26, left: 57},
        {top: 46, left: 58}
    ],
    'elite': [
        {top: 36, left: 38, rotate: 37},
        {top: 54, left: 50, rotate: 37},
        {top: 25, left: 55, rotate: 3},
        {top: 37, left: 63, rotate: 3}
    ],
    'fiveseven': [
        {top: 25, left: 35},
        {top: 25, left: 47},
        {top: 26, left: 56},
        {top: 48, left: 58}
    ],
    'glock': [
        {top: 24, left: 38},
        {top: 24, left: 46},
        {top: 26, left: 56},
        {top: 43, left: 57}
    ],
    'hkp2000': [
        {top: 23, left: 38},
        {top: 24, left: 47},
        {top: 28, left: 56},
        {top: 55, left: 58}
    ],
    'p250': [
        {top: 22, left: 38},
        {top: 22, left: 48},
        {top: 25, left: 57},
        {top: 49, left: 59}
    ],
    'tec9': [
        {top: 26, left: 45.5},
        {top: 22, left: 52},
        {top: 21, left: 59},
        {top: 21, left: 67}
    ],
    'usp_silencer': [
        {top: 22, left: 56},
        {top: 23, left: 66},
        {top: 26, left: 79},
        {top: 59, left: 81}
    ],
    'mac10': [
        {top: 23, left: 40},
        {top: 23, left: 51},
        {top: 23, left: 57},
        {top: 39, left: 51}
    ],
    'mp5sd': [
        {top: 33, left: 36},
        {top: 33, left: 45},
        {top: 36, left: 53},
        {top: 33, left: 60}
    ],
    'mp7': [
        {top: 25, left: 50},
        {top: 26, left: 57},
        {top: 49, left: 50.5},
        {top: 31, left: 63}
    ],
    'mp9': [
        {top: 23, left: 19},
        {top: 23, left: 33},
        {top: 47, left: 34},
        {top: 21, left: 43}
    ],
    'p90': [
        {top: 45, left: 39},
        {top: 50, left: 63},
        {top: 44, left: 71},
        {top: 55, left: 79}
    ],
    'bizon': [
        {top: 28, left: 33},
        {top: 41, left: 33},
        {top: 31, left: 41},
        {top: 31, left: 48}
    ],
    'ump45': [
        {top: 21, left: 29},
        {top: 26, left: 38},
        {top: 29, left: 46},
        {top: 15, left: 54}
    ],
    'm249': [
        {top: 50, left: 48.5},
        {top: 38, left: 54},
        {top: 44, left: 59},
        {top: 38, left: 66}
    ],
    'negev': [
        {top: 35, left: 37},
        {top: 43, left: 43.5},
        {top: 37, left: 53},
        {top: 35, left: 60}
    ],
    'awp': [
        {top: 26, left: 48},
        {top: 39, left: 56},
        {top: 37, left: 62},
        {top: 43, left: 76}
    ],
    'g3sg1': [
        {top: 39, left: 45},
        {top: 26, left: 68},
        {top: 40, left: 67},
        {top: 43, left: 76},
        {top: 48, left: 83}
    ],
    'scar20': [
        {top: 40, left: 46},
        {top: 50, left: 49},
        {top: 44, left: 55},
        {top: 44, left: 67}
    ],
    'ssg08': [
        {top: 46, left: 45},
        {top: 52, left: 49.5},
        {top: 49, left: 55.5},
        {top: 51, left: 63}
    ]
}

export {WeaponStickersPosition}