import {FaCheck, FaRegClock, FaUser} from "react-icons/fa";
import {useEffect, useState} from "react";
import {axiosApi} from "../../Api";
import {IoAddCircleOutline} from "react-icons/io5";
import {IoIosInformationCircleOutline, IoMdRemoveCircleOutline} from "react-icons/io";

export default function TabPrivilege({profile, tab}) {

    const [moneyActions, setMoneyActions] = useState([])

    useEffect(() => {

        axiosApi.post('/user/get_money_actions').then(response => {

            setMoneyActions(response.data)

        }).catch(error => {
            console.log('[/user/get_money_actions]', error)
        })

    }, [tab])

    return (

        <>
            <div className="banner-block yellow testbanner">
                <div className="background"></div>
                <div className="title">Мои привилегии и транзакции</div>
                <div className="text">
                    Здесь вы можете посмотреть свои привилегии, а также список ваших транзакций
                </div>
            </div>


            <div className="block">
                <div className="block-title">Мои привилегии</div>
                <div className="privilege">
                    {profile.role.end ? (
                        <div className="item">
                            <div className="active">
                                <div className="icon green">
                                    <FaCheck />
                                </div>
                                Активная
                            </div>
                            <div className="name">
                                <div className="icon grey">
                                    <FaUser />
                                </div>
                                {profile.role.name}
                            </div>
                            <div className="end">
                                <div className="icon grey">
                                    <FaRegClock />
                                </div>
                                {profile.role.end}
                            </div>
                        </div>
                    ) : (
                        <span>У вас нет активных привилегий</span>
                    )}
                </div>
            </div>

            <div className="block">
                <div className="block-title">Мои транзакции</div>
                <div className="transactions-list">
                    {moneyActions.map((item, key) => (

                        <div className="item" key={key}>
                            <div className="description">
                                <div className="icon purple">
                                    <IoIosInformationCircleOutline />
                                </div>
                                {item.description}
                            </div>
                            <div className={`amount ${item.amount > 0 ? 'add' : 'remove'}`}>
                                {item.amount > 0 ? (
                                    <div className="icon green">
                                        <IoAddCircleOutline />
                                    </div>
                                ) : (
                                    <div className="icon red">
                                        <IoMdRemoveCircleOutline />
                                    </div>
                                )}
                                {item.amount} руб.
                            </div>
                            <div className="date">
                                <div className="icon grey">
                                    <FaRegClock />
                                </div>
                                {item.date}
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </>

    )

}