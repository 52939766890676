import Axios from 'axios';

const apiDomain = 'https://api.cstrigon.net'
const apiURL = apiDomain+'/api/v1'
const axiosApi = Axios.create({
    baseURL: apiURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

export {apiURL, axiosApi, apiDomain}

